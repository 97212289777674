<template>
  <component :is="treatment === undefined ? 'div' : 'b-card'">
   

    <b-tabs v-if="treatment" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">{{$t('MainData')}}</span>
        </template>
        <add-edit-treatment-surgery-main :treatment="treatment" :doctorList="doctorList" @getDoctors="getHospitalDoctor" class="mt-2 pt-75" />
      </b-tab>

      <!-- Tab: Items -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('Items')}}</span>
        </template>
        <add-edit-treatment-surgery-items :treatment="treatment" :doctorList="doctorList"  :addItemModel="addItemModel" class="mt-2 pt-75" />

      </b-tab>
        <!-- Tab: Documents -->
        <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('Documents')}}</span>
        </template>
        <add-edit-treatment-surgery-document :treatment="treatment" :doctorList="doctorList"  class="mt-2 pt-75" />

      </b-tab>

       <!-- Tab: Doctors -->
       <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline"> {{$t('Doctors')}}</span>
        </template>
        <add-edit-treatment-surgery-doctors :treatment="treatment" :doctorList="doctorList" class="mt-2 pt-75" />

      </b-tab>

    </b-tabs>
       <!-- Action Buttons -->
       <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="SaveHospitalSurgery()"
    >
      {{$t('SaveChanges')}}
    </b-button>
    <b-button
    type="border" color="danger"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="$router.go(-1)"
    >
      {{$t('back')}}
    </b-button>
  </component>
</template>


<script>
import point from "@/components/point.vue";
import addItem from "@/components/surgery/addItem.vue";
import HospitalModule from "@/store/hospital/moduleHospital.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import moduleCurrency from "@/store/settings/currency/moduleCurrency.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleSurgeryCategory from "@/store/settings/surgeryCategory/moduleSurgeryCategory.js";
import moduleSurgeryItem from "@/store/settings/surgeryItem/moduleSurgeryItem.js";
import AddSurgeryItemPopup from "./AddSurgeryItemPopup.vue";
import { BAlert, BButton, BCard, BLink, BTab, BTabs } from "bootstrap-vue";
import AddEditTreatmentSurgeryDocument from "./AddEditTreatmentSurgeryDocument.vue";
import AddEditTreatmentSurgeryDoctors from "./AddEditTreatmentSurgeryDoctors.vue";
import AddEditTreatmentSurgeryItems from "./AddEditTreatmentSurgeryItems.vue";
import AddEditTreatmentSurgeryMain from "./AddEditTreatmentSurgeryMain.vue";

export default {
  components: {
    addItem,
    point,
    BAlert, BButton, BCard, BLink, BTab, BTabs,
    AddSurgeryItemPopup,
    AddEditTreatmentSurgeryDoctors,
    AddEditTreatmentSurgeryDocument,
    AddEditTreatmentSurgeryItems,
    AddEditTreatmentSurgeryMain
  },
  data() {
    return {
      EnglishLan: false,
      ShowAddSurgeryItemPopup: false,
      points: [],
      treatment: {
        Surgery: {},
        HospitalSurgeryDoctors: [],
        HospitalSurgeryRequiredDocuments: [],
        HospitalSurgeryExcludedItems: [],
        HospitalSurgeryIncludedItems: [],
      },
      addItemModel: {},
      doctorList: [],
      search: { DoctorName: "" },
      activeList: [],
    };
  },
  methods: {
   
    SaveHospitalSurgery() {     
      this.$vs.loading();
      debugger;
      if (this.treatment.IsPercentage) {
        this.treatment.Discount = undefined;
      } else {
        this.treatment.PercentageDiscount = undefined;
      }
      debugger;
      if (this.treatment.ID > 0) {
        this.$store
          .dispatch("HospitalList/UpdateHospitalSurgery", this.treatment)
          .then((res) => {
            this.$vs.loading.close();

            this.$router.go(-1);
            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.$router.go(-1);
            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      } else {
        debugger;
        this.treatment.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        this.$store
          .dispatch("HospitalList/AddHospitalSurgery", this.treatment)
          .then((res) => {
            this.$vs.loading.close();

            this.$router.go(-1);
            this.$vs.notify({
              title: this.$t("success"),
              text: res.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            
          })
          .catch((err) => {
            this.$vs.loading.close();

            this.$router.go(-1);
            this.$vs.notify({
              title: this.$t("Error"),
              text: err.data.Message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }

     
    },

    getHospitalDoctor() {
      debugger;

      this.$store
        .dispatch("HospitalList/SearchHospitalDoctors", {
          HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
          SpecialtyID: this.treatment.Surgery.SpecialtyID,
        })
        .then((res) => {
          debugger;
          var doctorLists = [];
          var model = res.data.Data;
          model.forEach((ele) => {
            if (doctorLists.findIndex((r) => r.ID == ele.Doctor.ID) == -1) {
              var obj = {};
              obj = ele.Doctor;
              obj.Specilties = model.filter(
                (b) =>
                  b.Doctor.ID == ele.Doctor.ID &&
                  !b.HospitalSpecialty.Specialty.ParentSpecialtyID
              );
              obj.SubSpecilties = model.filter(
                (b) =>
                  b.Doctor.ID == ele.Doctor.ID &&
                  b.HospitalSpecialty.Specialty.ParentSpecialtyID
              );
              doctorLists.push(obj);
            }
          });
          doctorLists.forEach((d) => {
            d.SubSpecilties.forEach((s) => (s.Doctor = {}));
            d.Specilties.forEach((s) => (s.Doctor = {}));
          });
          this.doctorList = doctorLists;

        //  this.setSelectedDoctor();
        });
      debugger;
    },
    searchHospitalSpecialty() {
      this.$store.dispatch("HospitalList/SearchHospitalSpecialtys", {
        HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
      });
    },
   
    GetHospitalSurgery() {
      debugger;
      const ID = this.$route.params.HospitalSurgeryID;
      if (ID) {
        this.$vs.loading();
        debugger;
        this.$store
          .dispatch("patientList/GetHospitalSurgery", ID)
          .then((res) => {
            debugger;
            if (res.status == 200) {
              this.treatment = res.data.Data;
    // this.$store.dispatch("SurgeryItemList/GetAllSurgeryItems",this.treatment.Surgery.ID);

              debugger;
            if(this.treatment.HospitalSurgeryDoctors==null||this.treatment.HospitalSurgeryDoctors==undefined){
              this.treatment.HospitalSurgeryDoctors =[];
            }
            if(this.treatment.HospitalSurgeryRequiredDocuments==null||this.treatment.HospitalSurgeryRequiredDocuments==undefined){
              this.treatment.HospitalSurgeryRequiredDocuments =[];
            }
            if(this.treatment.HospitalSurgeryExcludedItems==null||this.treatment.HospitalSurgeryExcludedItems==undefined){
              this.treatment.HospitalSurgeryExcludedItems =[];
            }
            if(this.treatment.HospitalSurgeryIncludedItems==null||this.treatment.HospitalSurgeryIncludedItems==undefined){
              this.treatment.HospitalSurgeryIncludedItems =[];
            }
              if (this.treatment.PercentageDiscount != null) {
                this.$set(this.treatment, "IsPercentage", true);
                debugger;
              } else {
                debugger;
               this.$set(this.treatment, "IsPercentage", false);
              }
              // this.$set(this.treatment, "IsPercentage", true);

              this.getHospitalDoctor();
              if (
                this.Model.Hospital.Rate == null ||
                this.Model.Hospital.Rate == undefined ||
                this.Model.Hospital.Rate == "NaN"
              ) {
                this.Model.Hospital.Rate = 0;
              }
              //this.setSelectedDoctor();
            }
            this.$vs.loading.close();
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
    setSelectedDoctor(data) {
      debugger
      var newdocList = [];
      if (this.doctorList && this.treatment.HospitalSurgeryDoctors) {
        this.doctorList.forEach((ele) => {
          var index =  this.treatment.HospitalSurgeryDoctors.findIndex(
              (obj) => obj.Doctor.ID == ele.ID)
          if (index == -1)  {
            // ele.IsSelected = true;
            newdocList.push(ele);
          }        
             
        });
        this.doctorList = newdocList;
      }

    },
  
   
  },
  computed: {
  
    isFormValid()
    {
       return this.treatment.Surgery.NameEN 
       && this.treatment.Surgery.SurgeryCategoryID
       && this.treatment.Surgery.SpecialtyID
       && this.treatment.DefaultHospitalPrice
       && this.treatment.CurrencyID
    },
   
  },
  created() {
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;


 if (!moduleSurgeryItem.isRegistered) {
      this.$store.registerModule("SurgeryItemList", moduleSurgeryItem);
      moduleSurgeryItem.isRegistered = true;
    }

    if (!moduleSurgeryCategory.isRegistered) {
      this.$store.registerModule("SurgeryCategoryList", moduleSurgeryCategory);
      moduleSurgeryCategory.isRegistered = true;
    }
    if (!HospitalModule.isRegistered) {
      this.$store.registerModule("HospitalList", HospitalModule);
      HospitalModule.isRegistered = true;
    }
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCurrency.isRegistered) {
      this.$store.registerModule("CurrencyList", moduleCurrency);
      moduleCurrency.isRegistered = true;
    }
    
    if(!this.currencies || this.currencies.length==0)
      this.$store.dispatch("CurrencyList/GetAllCurrencies");

    this.$store.dispatch("SurgeryItemList/GetAllSurgeryItemsLookup");

    this.GetHospitalSurgery();
    this.searchHospitalSpecialty();
    //this.getHospitalDoctor();
    if(!this.surgeryCategories || this.surgeryCategories.length==0)
      this.$store.dispatch("SurgeryCategoryList/GetAllSurgeryCategories");
  },
};
</script>
<style>
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
#custumcard .vs-card--content {
  padding: unset;
}
#customRow .vx-col {
  padding: unset;
}

#addsurgery .vx-row {
  margin: unset;
}
</style>
