/*=========================================================================================
  File Name: moduleSurgeryCategoryActions.js
  Description: SurgeryCategory Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    AddSurgeryCategory({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryCategory/AddSurgeryCategory", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetAllSurgeryCategories({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("api/SurgeryCategory/GetAllSurgeryCategories")
                .then((response) => {
                    commit('SET_SurgeryCategory', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SearchSurgeryCategories({ commit },data) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryCategory/SearchSurgeryCategories",data)
                .then((response) => {
                    commit('SET_SurgeryCategory', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    getSurgeryItems({ commit }) {
        return new Promise((resolve, reject) => {
            axios.get("api/SurgeryItemLockUp/GetAllSurgeryItemLockUps")
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    UpdateSurgeryCategory({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryCategory/UpdateSurgeryCategory", item)
                .then((response) => {
                    commit('UPDATE_SurgeryCategory', item)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    GetSurgeryCategory({ commit }, itemid) {
        return new Promise((resolve, reject) => {
            axios.post("api/SurgeryCategory/GetSurgeryCategory", itemid)
                .then((response) => {
                    commit('UPDATE_SurgeryCategory', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteSurgeryCategory({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.delete("api/SurgeryCategory/DeleteSurgeryCategory?ID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
}