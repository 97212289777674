<template>
    <div>
       <div class="vx-row" @click="AddNewItem()">
            <div style="border-radius:50px;width: 20px; height: 20px;curser:pointer" class="primaryBackColor p-1 m-1">
                <feather-icon style="color: white;width: 15px;height: 15px;" icon="PlusIcon"></feather-icon>
            </div>
            <u class="mt-2 primarycolor text-bold">{{$t("AddanotherItem")}} </u>
       </div>
    </div>
</template>
<script>
export default {
  
    methods:{
        AddNewItem()
        {
           this.$emit('event');
        }
    }
}
</script>