/*=========================================================================================
  File Name: moduleSurgeryItemActions.js
  Description: SurgeryItem Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  AddSurgeryItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/SurgeryItem/AddSurgeryItem", item)
        .then((response) => {
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllSurgeryItems({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/SurgeryItem/GetAllSurgeryItems")
        .then((response) => {
          commit('SET_SurgeryItem', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetAllSurgeryItemsLookup({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("api/SurgeryItemLockUp/GetAllSurgeryItemLockUps")
        .then((response) => {
          commit('SET_SurgeryItem', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetAllSurgeryItems({ commit },surgeryid) {
    return new Promise((resolve, reject) => {
      axios.get("api/Surgery/GetSurgeryItems?ID="+surgeryid)
        .then((response) => {
          commit('SET_SurgeryItem', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  UpdateSurgeryItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.post("api/SurgeryItem/UpdateSurgeryItem", item)
        .then((response) => {
          commit('UPDATE_SurgeryItem', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetSurgeryItem({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.post("api/SurgeryItem/GetSurgeryItem", itemid)
        .then((response) => {
          commit('UPDATE_SurgeryItem', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  DeleteSurgeryItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      axios.delete("api/SurgeryItem/DeleteSurgeryItem?ID="+item.ID)
        .then((response) => {
          commit('REMOVE_ITEM', item.ID)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
