<template>
  <div id="addsurgery">
    <b-form>
      <b-row>
      <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('NameEn')"
            label-for="NameEn"
          >
            <b-form-input
              id="NameEn"
              v-model="treatment.Surgery.NameEN"
            />
          </b-form-group>
        </b-col>
     

      <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('NameAr')"
            label-for="NameAr"
          >
            <b-form-input
              id="NameAr"
              v-model="treatment.Surgery.NameAR"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('SurgeryCategory')"
            label-for="SurgeryCategory"
          >
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          label="Name"
          v-model="treatment.Surgery.SurgeryCategoryID"
          :options="surgeryCategories"
          :reduce="(ID) => ID.ID"
          :placeholder="$t('SurgeryCategory')"
          :disabled="treatment.ID > 0"
        >
        </v-select>
          </b-form-group>
        </b-col>
      
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('Specialities')"
            label-for="Specialities"
          >
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          label="Name"
          v-model="treatment.Surgery.SpecialtyID"
          :options="specialities"
          :reduce="(ID) => ID.ID"
          :placeholder="$t('Specialities')"
          @input="changeSpecialty"
          :disabled="treatment.ID > 0"
        >
        </v-select>
          </b-form-group>
        </b-col>
    
        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('HospitalPrice')"
            label-for="HospitalPrice"
          >
            <b-form-input
              id="HospitalPrice"
              v-model="treatment.DefaultHospitalPrice"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('DoctorPriceSurgery')"
            label-for="DoctorPriceSurgery"
          >
            <b-form-input
              id="HospitalPrice"
              v-model="treatment.DefaultDoctorPrice"
            />
          </b-form-group>
        </b-col>


        <b-col
          cols="12"
          md="3"
        >
          <b-form-group
            :label="$t('Currency')"
            label-for="Currency"
          >
          <v-select
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          label="Name"
          v-model="treatment.CurrencyID"
          :options="currencies"
          :placeholder="$t('Currency')"
          :reduce="(obj) => obj.ID"
        />
          </b-form-group>
        </b-col>

     <b-col cols="12"
          md="3">
      <b-form-group :label="$t('Discount')">
          <b-row>
            <b-form-radio-group
            id="Value-radio-group"
            v-model="treatment.IsPercentage"
            name="Value"
            :options="[{text:$t('Value'),value:false}]"
          />
          <b-form-radio-group
            id="IsPercentage-radio-group"
            v-model="treatment.IsPercentage"
            name="IsPercentage"
            :options="[{text:$t('Percentage'),value:true}]"
          />
          </b-row>
         
        </b-form-group>
     </b-col>

     <b-col
          cols="12"
          md="3"
          v-if="treatment.IsPercentage != false &&treatment.IsPercentage !=null"
        >
          <b-form-group
            :label="$t('PercentageDiscount')"
            label-for="PercentageDiscount"
          >
            <b-form-input
              id="PercentageDiscount"
              v-model="treatment.PercentageDiscount"
            />
          </b-form-group>
        </b-col>
    
        <b-col
          cols="12"
          md="3"
          v-if="treatment.IsPercentage != true &&treatment.IsPercentage !=null"
        >
          <b-form-group
            :label="$t('Discount')"
            label-for="Discount"
          >
            <b-form-input
              id="Discount"
              v-model="treatment.Discount"
            />
          </b-form-group>
        </b-col>
         
    <b-col
          cols="12"
          md="3"
        >
        <b-form-group :label="$t('Active')">
          <b-form-checkbox
              v-model="treatment.Active"
              switch
              inline
            >
              
            </b-form-checkbox>
        </b-form-group>
        </b-col>
      </b-row>
  </b-form>
  </div>
</template>
<script>

import { BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'

export default {
  components: {
        BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable
  },
  data() {
    return {
      EnglishLan: false,
      points: [],
      
     // doctorList: [],
      search: { DoctorName: "" },
    };
  },
  props: {
    treatment:{
      type: Object,
      required: true,
    },
    doctorList:{
      type:Array,
      required:true,
    }
  },
  methods: {
    
   
    searchHospitalSpecialty() {
      this.$store.dispatch("HospitalList/SearchHospitalSpecialtys", {
        HospitalID: this.$store.state.AppActiveUser.Hospital.ID,
      });
    },
    changeSpecialty() {
      debugger;
      this.$emit('getDoctors');
    },
   
  
  },
  computed: {
   
   
    checkPercentage() {
      debugger;
      if (this.treatment.IsPercentage == true) {
        debugger;
        return true;
      } else {
        debugger;
        return false;
      }
    },
    currencies() {
      return this.$store.state.CurrencyList.currencies;
    },
  
    hospitalDoctors() {
      return this.$store.state.HospitalList.hospitalDoctors;
    },
    surgeryCategories() {
      return this.$store.state.SurgeryCategoryList.surgeryCategories;
    },
    specialities() {
      return this.$store.state.HospitalList.hospitalSpecialties;
    },
  },
  created() {
   
  },
};
</script>
<style>
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}
#custumcard .vs-card--content {
  padding: unset;
}
#customRow .vx-col {
  padding: unset;
}

#addsurgery .vx-row {
  margin: unset;
}
</style>
