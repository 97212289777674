<template>
  <div id="addsurgery">

    <div style="width: 100%; margin: auto">

      <!-- tab 4  -->

      <p>{{ $t("AssignDoctors") }}</p>

      <b-row>
        <b-col cols="12" md="5">
          <vs-card style="height: 40vh; overflow-y: scroll">
            <h3 class="ActiveCardHeader primaryBackColor">{{ $t("Doctors") }}</h3>
            <div class="m-1">
              <vs-input v-model="search.DoctorName" class="w-full" name="search" :placeholder="$t('Search')" />
              <div v-for="item in filterDoctor" :key="item.ID" class="shadow-md w-full mt-2">
                <b-row>
                  <b-form-checkbox v-model="item.IsSelected" @input="ChangeDoctorSelect(item)"></b-form-checkbox>
                  {{ item.Name }}
                </b-row>
              </div>
            </div>
          </vs-card>
        </b-col>
        <b-col cols="12" md="1" class="mt-5">

          <b-button :disabled="doctorSelected.length == 0" size="small" @click="gofromDoctorToDoctorSelect"
            :variant="doctorSelected.length == 0 ? 'white' : 'primary'"
            style="border-radius: 20%; opacity: 1; border-color: black;border: 2px solid #1F1F1F">
            <feather-icon :icon="!$vs.rtl ? 'ArrowRightIcon' : 'ArrowLeftIcon'" class="cursor-pointer"></feather-icon>
          </b-button>
          <br />

          <b-button :disabled="doctorUnSelected.length == 0" size="small" @click="gofromDoctorSelectToDoctor" class="mt-1"
            :variant="doctorUnSelected.length == 0 ? 'white' : 'primary'"
            style="border-radius: 20%; opacity: 1; border-color: black;border: 2px solid #1F1F1F">
            <feather-icon :icon="!$vs.rtl ? 'ArrowLeftIcon' : 'ArrowRightIcon'" class="cursor-pointer"></feather-icon>
          </b-button>


        </b-col>
        <b-col cols="12" md="6">
          <vs-card class="" style="height: 40vh; overflow-y: scroll">
            <h3 class="ActiveCardHeader secondaryBackColor">{{ $t("DoctorsSelected") }}</h3>
            <div v-for="(item, index) in treatment.HospitalSurgeryDoctors" :data="item.Doctor" :key="index"
              class=" mt-2 m-1">
              <b-row>
                <b-form-checkbox v-model="item.IsSelected" @input="ChangeDoctorUnSelect(item)"></b-form-checkbox>
                <span v-if="item.Name"> {{ item.Name }}</span>
                <span v-else> {{ item.Doctor.Name }}</span>

              </b-row>
            </div>

          </vs-card>
        </b-col>

      </b-row>

    </div>

  </div>
</template>
<script>

import {
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCol,
  BImg,
  BLink,
  BRow,
  BFormCheckbox
} from "bootstrap-vue";
export default {
  components: {
    BButton,
    BCard,
    BCardBody,
    BCardFooter,
    BCardHeader,
    BCardText,
    BCol,
    BImg,
    BLink,
    BRow,
    BFormCheckbox
  },
  data() {
    return {
      doctorSelected: [],
      doctorUnSelected: [],
      // doctorList: [],
      search: { DoctorName: "" },
      activeList: [],
    };
  },
  methods: {
    ChangeDoctorSelect(doctor) {
      if (doctor.IsSelected == true) {
        this.doctorSelected.push(doctor)

      }
      else {
        var index = this.doctorSelected.findIndex(
          (obj) => obj.ID == doctor.ID
        );
        this.doctorSelected.splice(index, 1);

      }
    },
    gofromDoctorToDoctorSelect() {
      debugger
      this.doctorSelected.forEach(doctor => {
        doctor.IsSelected = false
        this.treatment.HospitalSurgeryDoctors.push({
          DoctorID: doctor.ID,
          Doctor: doctor,
          Name: doctor.Name
        });

        debugger;
        var index = this.doctorList.findIndex(
          (obj) => obj.ID == doctor.ID
        );
        if (index == -1) {
          this.doctorList.push(doctor);
        } else {
          this.doctorList.splice(index, 1);
        }

      });
      this.doctorSelected = [];

    },
    ChangeDoctorUnSelect(doctor) {
      if (doctor.IsSelected == true) {
        this.doctorUnSelected.push(doctor)

      }
      else {
        var index = this.doctorUnSelected.findIndex(
          (obj) => obj.DoctorID == doctor.DoctorID
        );
        this.doctorUnSelected.splice(index, 1);

      }

    },
    gofromDoctorSelectToDoctor() {
      debugger
      this.doctorUnSelected.forEach(doctor => {
        doctor.IsSelected = false

        var index = this.treatment.HospitalSurgeryDoctors.findIndex(
          (obj) => obj.DoctorID == doctor.DoctorID
        );
        this.doctorList.push(doctor.Doctor);
        this.treatment.HospitalSurgeryDoctors.splice(index, 1);
      });
      this.doctorUnSelected = [];
    },

    // DoctorChecked(doctor) {
    //   debugger;
    //   var index = this.treatment.HospitalSurgeryDoctors.findIndex(
    //     (obj) => obj.Doctor.ID == doctor.ID
    //   );
    //   if (index == -1) {
    //     this.treatment.HospitalSurgeryDoctors.push({
    //       DoctorID: doctor.ID,
    //       Doctor: doctor,
    //       Name:doctor.Name
    //     });
    //   } else {
    //     this.treatment.HospitalSurgeryDoctors.splice(index, 1);
    //   }
    //   this.DoctorUnChecked(doctor);
    // },
    // DoctorUnChecked(doctor){
    //   debugger;
    //   var index = this.doctorList.findIndex(
    //     (obj) => obj.ID == doctor.ID
    //   );
    //   if (index == -1) {
    //     this.doctorList.push(doctor);
    //   } else {
    //     this.doctorList.splice(index, 1);
    //   }
    // },


  },
  computed: {

    filterDoctor() {
      if (this.search.DoctorName) {
        debugger;
        var vm = this;
        return this.doctorList.filter(function (obj) {
          return obj.Name.toLowerCase().indexOf(vm.search.DoctorName.toLowerCase()) > -1;
        });
      } else return this.doctorList;
    },
    hospitalDoctors() {
      return this.$store.state.HospitalList.hospitalDoctors;
    },

  },
  props: {
    treatment: {
      type: Object,
      required: true,
    },
    doctorList: {
      type: Array,
      required: true,
    }
  },
  created() {
    //
  },
};
</script>
<style>
.ActiveCardHeader {
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

#custumcard .vs-card--content {
  padding: unset;
}

#customRow .vx-col {
  padding: unset;
}

#addsurgery .vx-row {
  margin: unset;
}
</style>
