<template>
  <div id="addsurgery">
  
    <b-row>
 <!-- tab 2  -->
 <b-col cols="12" md="6"> 
      <b-card>
        <div >
          <div >
            {{ $t("SurgeryTreatmentIncludes") }}
          </div>

            <div id="data-list-list-view" class="data-list-container">
              <b-button class="m-1" variant="primary" @click=" ShowAddSurgeryItemPopup = true;
                activeList = treatment.HospitalSurgeryIncludedItems;
                addItemModel = { viewType: 1 };
              ">
                      {{ $t('AddanotherItem') }}

              </b-button>
          <vs-table
            ref="table"
            :data="treatment.HospitalSurgeryIncludedItems"
          >

            <template slot="thead">
              <vs-th >{{ $t("Name") }}</vs-th>
              <vs-th >{{ $t("Action") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ item.SurgeryItem.Name }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <b-button variant="primary" @click="removeSurgeryIncludedItem(
                  treatment.HospitalSurgeryIncludedItems,
                  item,
                  indextr
                )">
                      {{ $t('Remove') }}
                    </b-button>
                  </vs-td>
                
                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
          
          </div>
        </div>
      </b-card>
    </b-col>


    <b-col cols="12" md="6"> 
      <b-card >
        <div>
          <div >
            {{ $t("SurgeryTreatmentNotIncludes") }}
          </div>

            <div id="data-list-list-view" class="data-list-container">
              <b-button  class="m-1" variant="primary" @click=" ShowAddSurgeryItemPopup = true;
                activeList = treatment.HospitalSurgeryExcludedItems;
                addItemModel = { viewType: 1 };
              ">
               {{ $t('AddanotherItem') }}
              </b-button>
          <vs-table
            ref="table"
            :data="treatment.HospitalSurgeryExcludedItems"
          >

            <template slot="thead">
              <vs-th >{{ $t("Name") }}</vs-th>
              <vs-th >{{ $t("Action") }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <tbody :data="item" :key="indextr" v-for="(item, indextr) in data">
                <vs-tr class="cursor-pointer hover:text-primary">
                  <vs-td>
                    <p class="product-name font-medium truncate">
                      
                      {{ item.SurgeryItem.Name }}
                    </p>
                  </vs-td>
                  <vs-td>
                    <b-button variant="primary" @click="removeSurgeryExcludedItem(
                  treatment.HospitalSurgeryExcludedItems,
                  item,
                  indextr
                )">
                      {{ $t('Remove') }}
                    </b-button>
                  </vs-td>
                
                 </vs-tr>
              </tbody>
            </template>
          </vs-table>
           
          </div>
        </div>
      </b-card>
    </b-col>

   

</b-row>
    <vs-popup title="" :active.sync="ShowAddSurgeryItemPopup">
      <AddSurgeryItemPopup
        :itemModel="addItemModel"
        v-if="ShowAddSurgeryItemPopup"
        @closePop="ShowAddSurgeryItemPopup = false"
        @AddItem="AddNewItemToList()"
      ></AddSurgeryItemPopup>
    </vs-popup>

  </div>
</template>
<script>
import AddSurgeryItemPopup from "./AddSurgeryItemPopup.vue";
import { BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable } from 'bootstrap-vue'

export default {
  components: {
    AddSurgeryItemPopup,BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm, BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable
  },
  data() {
    return {
      ShowAddSurgeryItemPopup: false,
     
    };
  },
  props: {
    treatment:{
      type: Object,
      required: true,
    },
    addItemModel:{
      type: Object,
      required: true,
    },
    doctorList:{
      type:Array,
      required:true,
    }
  },
  methods: {
    // ChangeIsPercentage(check) {
    //   debugger;
    //   this.treatment.IsPercentage = check;
    //   debugger;
    // },
    AddNewItemToList() {
      debugger
      if(this.activeList==null||this.activeList==undefined){
        this.activeList = []
      }
      debugger
      if (this.addItemModel.viewType == 1)
      {
      var vm=this;
        var item=this.SurgeryItems.filter(x=>x.ID == vm.addItemModel.SurgeryItemID&&x.ID!=0)[0];
        this.activeList.push({
         // SurgeryItemID : item.ID,
          SurgeryItem: {
          
            SurgeryItemLockUpId:  this.addItemModel.SurgeryItemID==0?null:this.addItemModel.SurgeryItemID,
            Name: item!=undefined?item.Name : this.addItemModel.Name,
            NameEN: item!=undefined?item.Name: this.addItemModel.Name,
            NameAR: item!=undefined?item.Name :this.addItemModel.Name,
          },
        });
      }
        
debugger
      if (this.addItemModel.viewType == 2) this.activeList.push(this.addItemModel);
debugger
      this.ShowAddSurgeryItemPopup = false;
    },
  
    removeSurgeryIncludedItem(list, item, index) {
      debugger;
      list.splice(index, 1);
      if (item.ID) {
        debugger;
        this.$store.dispatch("HospitalList/DeleteSurgeryIncludeItem", item.ID);
      }
    },
    removeSurgeryExcludedItem(list, item, index) {
      debugger;
      list.splice(index, 1);
      if (item.ID) {
        debugger;
        this.$store.dispatch("HospitalList/DeleteSurgeryExcludeItem", item.ID);
      }
    },
  
   
  },
  computed: {
    SurgeryItems()
    {
       return this.$store.state.SurgeryItemList.surgeryItems;
    },
  },
  created() {
   
  },
};
</script>


<style lang="scss">
#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          width: fit-content;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
.vs-sidebar.vs-sidebar-parent {
  max-height: 1500px !important;
}
</style>


