var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',[_c('div',{staticClass:"data-list-container",attrs:{"id":"data-list-list-view"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){_vm.ShowAddSurgeryItemPopup = true;
               _vm.activeList = _vm.treatment.HospitalSurgeryRequiredDocuments;
               _vm.addItemModel = { viewType: 2 };}}},[_vm._v(" "+_vm._s(_vm.$t('AddanotherItem'))+" ")]),_c('vs-table',{ref:"table",attrs:{"data":_vm.treatment.HospitalSurgeryRequiredDocuments},scopedSlots:_vm._u([{key:"default",fn:function(ref){
               var data = ref.data;
return _vm._l((data),function(item,index){return _c('tbody',{key:index,attrs:{"data":item}},[_c('vs-tr',{staticClass:"cursor-pointer hover:text-primary"},[_c('vs-td',[_c('p',{staticClass:"product-name font-medium truncate"},[_vm._v(" "+_vm._s(item.Description)+" ")])]),_c('vs-td',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.removeDocumentItem(
                 _vm.treatment.HospitalSurgeryRequiredDocuments,
                 item,
                 index
               )}}},[_vm._v(" "+_vm._s(_vm.$t('Remove'))+" ")])],1)],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v(_vm._s(_vm.$t("Name")))]),_c('vs-th',[_vm._v(_vm._s(_vm.$t("Action")))])],1)],2)],1)])]),_c('vs-popup',{attrs:{"title":"","active":_vm.ShowAddSurgeryItemPopup},on:{"update:active":function($event){_vm.ShowAddSurgeryItemPopup=$event}}},[(_vm.ShowAddSurgeryItemPopup)?_c('AddSurgeryItemPopup',{attrs:{"itemModel":_vm.addItemModel},on:{"closePop":function($event){_vm.ShowAddSurgeryItemPopup = false},"AddItem":function($event){return _vm.AddNewItemToList()}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }