<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="MyForm">
    <div slot="no-body">
      <div class="justify-center items-center customBG">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <div class="p-5">
            <div class="MyPopClose">
            </div>
            <!-- Name -->
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-if="itemModel.viewType == 1"
              class="w-full mt-2"
              label="Name"
              v-model="itemModel.SurgeryItemID"
              :options="SurgeryItems"
              :reduce="(ID) => ID.ID"
              :placeholder="$t('Surgery Item')"
            />
            <vs-input
              v-if="itemModel.viewType == 1&&itemModel.SurgeryItemID==0"
              class="w-full"
              data-vv-validate-on="blur"
              name="name"
              icon-pack="feather"
              v-model="itemModel.Name"
              :icon-after="true"
              :label="$t('Name')"
            />
            <!-- Description -->
            <vs-input
              v-if="itemModel.viewType == 2"
              class="w-full"
              data-vv-validate-on="blur"
              name="Description"
              icon-pack="feather"
              v-model="itemModel.Description"
              :icon-after="true"
              :label="$t('Description')"
            />
            <!-- DocumentID -->
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-if="itemModel.viewType == 2"
              class="w-full mt-2"
              label="Name"
              v-model="itemModel.DocumentID"
              :options="DocumentTypes"
              :reduce="(ID) => ID.ID"
              :placeholder="$t('DocumentType')"
            />
            <!-- ScanTypeID -->
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-if="itemModel.DocumentID == 1 && itemModel.viewType == 2"
              class="w-full mt-2"
              label="Name"
              v-model="itemModel.ScanTypeID"
              :options="ScanTypes"
              :reduce="(ID) => ID.ID"
              placeholder="ScanType"
            />
            <!-- AnalysisTypeID -->
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-if="itemModel.DocumentID == 2 && itemModel.viewType == 2"
              class="w-full mt-2"
              label="Name"
              v-model="itemModel.AnalysisTypeID"
              :options="AnalysisTypes"
              :reduce="(ID) => ID.ID"
              placeholder="AnalysisType"
            />
            <!-- ReportTypeID -->
            <v-select
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-if="itemModel.DocumentID == 3 && itemModel.viewType == 2"
              class="w-full mt-2"
              label="Name"
              v-model="itemModel.ReportTypeID"
              :options="ReportTypes"
              :reduce="(ID) => ID.ID"
              placeholder="ReportType"
            />
            <div style="text-align: center">
              <b-button
                :disabled="vaildate"
                variant="primary"
                style="width: 60%; margin-top: 2rem"
                @click="$emit('AddItem')"
                >{{ $t("Save") }}</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import { BButton} from 'bootstrap-vue'

export default {
  data() {
    return {};
  },
  props: {
    itemModel: {
      type: Object,
      default: () => {},
    },
  },
  components: {BButton},
  methods: {},
  computed: {
    SurgeryItems()
    {
       var temp=  this.$store.state.SurgeryItemList.surgeryItems;
       if(!temp.filter(e=>e.ID==0)[0])
          temp.push({ID:0,Name:"Other"});
       return temp;
    },
    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
    ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
    ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    },
    vaildate() {
      if (
        ((this.itemModel.Name != null || this.itemModel.Description != null) &&
          this.itemModel.DocumentID != null &&
          (this.itemModel.ScanTypeID != null ||
            this.itemModel.AnalysisTypeID != null ||
            this.itemModel.ReportTypeID != null) &&
          this.itemModel.viewType == 2) ||
        ((this.itemModel.Name != null || this.itemModel.SurgeryItemID) && this.itemModel.viewType == 1)
      ) {
        debugger;
        return false;
      }
      return true;
    },
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
    if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }
    debugger;

    debugger;
    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
  },
};
</script>

<style lang="scss">
.MyForm {
  width: 50vh;
  margin: auto;
}
</style>
